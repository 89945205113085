import "core-js/modules/es.array.concat";
import Vue from 'vue';
var bus = new Vue({
  methods: {
    on: function on(event, callback) {
      this.$on(event, callback);
      return this;
    },
    once: function once(event, callback) {
      this.$once(event, callback);
      return this;
    },
    off: function off(event, callback) {
      this.$off(event, callback);
      return this;
    },
    emit: function emit(event) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this.$emit.apply(this, [event].concat(args));
      return this;
    }
  }
});
export default {
  install: function install(vue) {
    Object.defineProperty(vue.prototype, '$bus', {
      get: function get() {
        return bus;
      }
    });
  }
};