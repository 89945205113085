import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import Layout from '@/layout';
var componentsRouter = {
  path: '/components',
  component: Layout,
  redirect: '/components/list',
  name: 'ComponentDemo',
  meta: {
    title: 'Components'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/pages/components-demo/index');
    },
    name: 'demoList',
    meta: {
      title: 'DemoList'
    }
  }, {
    path: 'loading',
    component: function component() {
      return import('@/pages/components-demo/loading');
    },
    name: 'LoadingDemo',
    meta: {
      title: 'Loading'
    }
  }, {
    path: 'flyin',
    component: function component() {
      return import('@/pages/components-demo/flyin');
    },
    name: 'FlyinDemo',
    meta: {
      title: 'Flyin'
    }
  }, {
    path: 'back-to-top',
    component: function component() {
      return import('@/pages/components-demo/backToTop');
    },
    name: 'BackToTopDemo',
    meta: {
      title: 'BackToTop'
    }
  }]
};
export default componentsRouter;