import Cookies from 'js-cookie';
import { TOKEN_KEY, LANGUAGE_KEY, BUILD_KEY } from '@/constants/settings';
export var getToken = function getToken() {
  return Cookies.get(TOKEN_KEY);
};
export var setToken = function setToken(token) {
  return Cookies.set(TOKEN_KEY, token);
};
export var getBuild = function getBuild() {
  return Cookies.get(BUILD_KEY);
};
export var setBuild = function setBuild(Build) {
  return Cookies.set(BUILD_KEY, Build);
};
export var removeToken = function removeToken() {
  return Cookies.remove(TOKEN_KEY);
};
export var getLanguage = function getLanguage() {
  return Cookies.get(LANGUAGE_KEY);
};
export var setLanguage = function setLanguage(language) {
  return Cookies.set(LANGUAGE_KEY, language);
};
export var saveToLocalStorage = function saveToLocalStorage(name, content) {
  return window.localStorage.setItem(name, JSON.stringify(content));
};
export var readFromLocalStorage = function readFromLocalStorage(name) {
  return JSON.parse(window.localStorage.getItem(name));
};
export var cleanLocalStorage = function cleanLocalStorage(name) {
  return window.localStorage.removeItem(name);
};