import storage from 'good-storage';
var UserInfoKey = 'User-Info'; // 用户信息 {} {...}
// 获取用户信息

export function loadUserInfo() {
  return storage.get(UserInfoKey, {});
} // 保存用户信息

export function saveUserInfo(userInfo) {
  storage.set(UserInfoKey, userInfo);
  return userInfo;
} // 删除用户信息

export function removeUserInfo() {
  storage.remove(UserInfoKey);
  return {};
} // 获取地址信息

export function loadAddress() {
  return storage.get(AddressKey);
} // 保存地址信息

export function saveAddress(a) {
  storage.set(AddressKey, a);
  return a;
} // 删除地址信息

export function removeAddress() {
  storage.remove(AddressKey);
  return {};
}