import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import { saveUserInfo, removeUserInfo, loadUserInfo } from '@/utils/cache';
import { setToken, getToken, getBuild, setBuild, removeToken } from '@/core/services/cache';
import { loginInteractor, doctorInteractor } from '@/core';
import context from '../../main.js';
var state = {
  token: getToken(),
  build: getBuild(),
  userInfo: loadUserInfo() // 用户登录信息

};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_BUILD: function SET_BUILD(state, build) {
    state.build = build;
  },
  SET_USERINFO: function SET_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  }
};
var actions = {
  smsLogin: function smsLogin(_ref, params) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      loginInteractor.smsLogin(params).then(function (res) {
        // 存用户信息，token
        setToken(res.token);
        commit('SET_TOKEN', res.token);
        resolve(res);
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // 刷新用户信息
  refreshUserInfo: function refreshUserInfo(_ref2, p) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      doctorInteractor.fetchDoctorInfo().then(function (res) {
        // 存用户信息，token
        commit('SET_USERINFO', saveUserInfo(res.doctor));
        resolve(res);
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  saveToken: function saveToken(_ref3, t) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      setToken(t);
      commit('SET_TOKEN', t);
      context.$bus.emit('getToken', t);
      resolve();
    });
  },
  saveBuild: function saveBuild(_ref4, t) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      setBuild(t);
      commit('SET_BUILD', t);
      resolve();
    });
  },
  logout: function logout(_ref5) {
    var commit = _ref5.commit,
        state = _ref5.state,
        dispatch = _ref5.dispatch;
    commit('SET_TOKEN', '');
    removeUserInfo();
    removeToken();
  },
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};