import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
import { routes } from '@/router';
export default {
  name: 'Principal',
  computed: {
    keepAliveList: function keepAliveList(routes) {
      return [];
    },
    key: function key() {
      console.log(this.keepAliveList);
      console.log('keep-alive -- ' + this.$route.fullPath);
      return this.$route.fullPath;
    }
  },
  created: function created() {
    this.filterKeepAliveRouter(routes);
  },
  methods: {
    filterKeepAliveRouter: function filterKeepAliveRouter(routes) {
      var _this = this;

      _(routes).forEach(function (route) {
        if (route.meta && route.meta.keepAlive) {
          _this.keepAliveList.push(route.name);
        }

        if (route.children && route.children.length) {
          _this.filterKeepAliveRouter(route.children);
        }

        console.log(_this.keepAliveList);
      });
    }
  }
};