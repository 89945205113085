import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import dayjs from 'dayjs';

var timeFilter = function timeFilter(value) {
  value = value.toString();

  if (value) {
    if (value.length === 13) {
      return dayjs(Number(value)).format('YYYY-MM-DD HH:mm:ss');
    }

    return dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '-';
  }
};

export default {
  timeFilter: timeFilter
};