export var DoctorInfo = '/api/doctor_info';
export var DoctorIncomeList = '/api/income_app_list_new';
export var DoctorIncomeType = '/api/income_type';
export var DoctorBasics = '/api/doctor_basics';
export var DoctorSettlementBill = '/api/settlement_bill';
export var DoctorSettlementItem = '/api/settlement_item';
export var DoctorCashAppList = '/api/cash_app_list';
export var DoctorReport = '/api/doctor_report';
export var DoctorReportURL = '/api/doctor_report_url';
export var DoctorShareReport = '/api/doctor_share_report';
export var PrescriptionsConfigForm = '/api/prescriptions_config_form'; //医生配置诊金

export var PrescriptionsConfigSave = '/api/prescriptions_config_save'; //医生配置诊金保存

export var InviteUrl = '/api/invite_url'; //解密邀请同行

export var DoctorNewIncomes = '/api/v3/new_incomes';
export var DoctorNewIncomeDetail = '/api/v3/new_incomes_detail';
export var DoctorConfigSave = '/api/config_save';
export var DoctorConfigList = '/api/config_list';