import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.keys";
import _objectSpread from "/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import Vue from 'vue';
import { getLanguage } from '@/core/services/cache';
import { Locale } from 'vant';
import VueI18n from 'vue-i18n';
import vantZhLocale from 'vant/lib/locale/lang/zh-CN';
import vantEnLocale from 'vant/lib/locale/lang/en-US';
import enLocale from './en';
import zhLocale from './zh';
Vue.use(VueI18n);
var messages = {
  en: _objectSpread({}, enLocale),
  zh: _objectSpread({}, zhLocale)
};
export var getLocale = function getLocale() {
  var cookieLanguage = getLanguage();

  if (cookieLanguage) {
    return cookieLanguage;
  }

  var language = navigator.language.toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'zh';
};
export var VueVantLocales = function VueVantLocales() {
  var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getLocale();

  switch (lang) {
    case 'zh':
      Locale.use('zh-CN', vantZhLocale);
      break;

    case 'en':
      Locale.use('en-US', vantEnLocale);
      break;
  }
};
export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: getLocale(),
  messages: messages
});