export var setPost = '/api/set_post';
export var categoriesList = '/api/categories_list';
export var postList = '/api/post_list';
export var toggleVote = '/api/toggle_vote';
export var postDetail = '/api/post_detail';
export var commentList = '/api/comment_list';
export var setComment = '/api/set_comment';
export var toggleFollow = '/api/toggle_follow';
export var CommunityDoctorInfo = '/api/lz_doctor_info';
export var CommunityNewsList = '/api/news_list';
export var CommunityNewsFollowList = '/api/news_follow_list';
export var CommunityNewsVoteList = '/api/news_vote_list';
export var CommunityNewsCommentList = '/api/news_comment_list';
export var CommunityNewsSysList = '/api/news_sys_list';
export var CommunityFollowOFansList = '/api/follow_or_fans_list';
export var CommunityHotTagList = '/api/hot_tag_list';
export var CommunityFileUpload = '/common/file/upload';
export var CommunitySetComplaint = '/api/set_complaint';
export var CommunityComplaintTypeList = '/api/complaint_type_list';
export var CommunityToggleFavorite = '/api/toggle_favorite';
export var CommunityGetPostShareUrl = '/api/get_post_share_url';
export var CommunityDeletePost = '/api/delete_post';
export var CommunityCheckPostDrafts = '/api/check_post_drafts';
export var CommunityHandleScan = '/api/handle_scan';
export var Community = '';