import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon';
Vue.component('svg-icon', SvgIcon);

var req = require.context('./svg', false, /\.svg$/);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

requireAll(req);