export var MallIntegralInfo = '/api/integral_info';
export var MallStoreSlides = '/api/store_slides';
export var MallStoreList = '/api/store_index';
export var MallStoreIndex = '/api/v2/store_index';
export var MallBuyGoods = '/api/buy_goods';
export var MallGoodsInfo = '/api/goods_info';
export var MallAddressList = '/api/address_list';
export var MallCreateOrder = '/api/create_integral_order';
export var MallCreateAddress = '/api/create_address';
export var MallGetAllAreas = '/api/get_all_areas';
export var MallGetIntegralOrderList = '/api/integral_order_list';
export var MallGetLogisticsTracking = '/api/logistics_tracking';
export var MallDoctorCards = '/api/doctor_cards';
export var MallDoctorCardDetail = '/api/doctor_card_detail';
export var MallStoreActivities = '/api/store_activities';
export var MallStoreActivityDetail = '/api/store_activity_detail';
export var MallStoreCategories = '/api/store_categories';
export var ActivityReturn = '/api/activity_hg_detail';
export var ActivityReceive = '/api/activity_hg_receive';
export var ActivityGBDetail = '/api/activity_gb_detail';
export var MallCheckCoupons = '/api/mall_check_coupons';
export var GetImgConfig = '/common/get_img_config';
export var ActivityXTDetail = '/api/activity_xt_detail';
export var ActivityXTReceive = '/api/activity_xt_receive';
export var ActivityLKDetail = '/api/activity_lk_detail';
export var ActivityLKReceive = '/api/activity_lk_receive';
export var ActivityInviteIndex = '/api/activity_invite_index';
export var ActivityInviteDetail = '/api/activity_invite_detail';
export var ActivityInviteDetailCash = '/api/activity_invite_cash';
export var ActivityTargetDetail = '/api/activity_target_detail';
export var ActivitySettledDetail = '/api/activity_settled_detail';
export var ActivitySettledReceive = '/api/activity_settled_receive';
export var ActivityNationalDetail = '/api/activity_national_detail';
export var ActivitySubsidiesDetail = '/api/activity_subsidies_detail';
export var ActivityConfigDetail = '/api/activity_config_detail';
export var ActivityConfigReceive = '/api/activity_config_receive';
export var ActivityDoctorReport2022 = '/api/doctor_report_2022';
export var ActivityDoctorReport2022Share = '/api/doctor_share_report_2022';
export var ActivityDoctorReportURL = '/api/doctor_report_url';
export var ActivityLKDetailJan = '/api/activity_lk_detail_jan';
export var ActivityLKReceiveJan = '/api/activity_lk_receive_jan';
export var ActivityLKMultipleJan = '/api/activity_lk_multiple_jan';
export var ActivityReturn202307 = '/api/activity_hg_detail_202307';
export var ActivityReceive202307 = '/api/activity_hg_receive_202307';
export var ActivityPromotionDetail = '/api/v2/promotion_detail';
export var ActivityGF202309 = '/api/activity_gf_202309';
export var ActivityGFRecipes202309 = '/common/activity_gf_recipes_202309';
export var ActivityGFRecipes202309AddAccord = '/api/add_accord_to_index';
export var ActivityGFRecipes202309RecipeShare = '/api/recipe_share';
export var ActivityGFRecipes202309AccordRecipeShare = '/api/accord_recipe_share';
export var ActivityGFRecipes202309AppRecipeList = '/api/v2/recipe_list';
export var ActivityInviteDoctor202311 = '/api/invite_202311';
export var ActivityInviteDoctor202311InviteCash = '/api/invite_cash_202311';
export var ActivityInviteDoctor202311TotalIncome = '/api/total_income_detail_202311';
export var ActivityInviteDoctor202311InviteDetail = '/api/invite_detail_202311';
export var ActivityInviteDoctor202311JoinDetail = '/api/join_detail_202311';
export var ActivityGF202309Receive = '/api/activity_gf_202309_receive';
export var ActivityQuestionnaireSurveyDetails = '/api/questionnaire_survey_details';
export var ActivityQuestionnaireSurveySubmit = '/api/questionnaire_survey_submit';
export var ActivityFourKF202312 = '/api/kf_202312';
export var ActivityFourKF202312Receive = '/api/kf_202312_receive';
export var ActivityRed202401Detail = '/api/activity_lk_detail_202401';
export var ActivityRed202401Receive = '/api/activity_lk_receive_202401';
export var ActivityRed202401Multiple = '/api/activity_lk_multiple_202401';
export var ActivityDoctorReport2023 = '/api/doctor_report_2023';
export var ActivityDoctorReport2023Share = '/api/doctor_share_report_2023';
export var ActivityInviteDoctor202402 = '/api/invite_202402';
export var ActivityInviteDoctorCash202402 = '/api/invite_cash_202402';
export var ActivityInviteInvite202405 = '/api/kf_202405';
export var ActivityLogs = '/api/page_log';
export var ActivityCommonGetLockStr = '/common/get_lock_str';
export var ConfigActivityDetail = '/api/config_activity_detail';
export var ConfigActivityReceive = '/api/config_activity_receive';
export var ConfigActivityRewardList = '/api/config_activity_reward_list';
export var ConfigActivityQuestionList = '/api/config_activity_question_list';
export var ConfigActivityQuestionSave = '/api/config_activity_question_save';
export var ConfigActivityButtonReceive = '/api/config_activity_button_receive';
export var CheckDoctorCollegeServiceGroup = '/api/doctor_college_service_group';
export var ActivityDoctorReport2024 = '/api/doctor_report_2024';
export var ActivityDoctorReport2024Share = '/api/doctor_share_report_2024';
export var AppAllSlideList = '/api/slide_list';