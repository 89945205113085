//
//
//
//
//
//
//
// import ServiceWorkerUpdatePopup from '@/pwa/components/ServiceWorkerUpdatePopup.vue'
import store from '@/store';
export default {
  name: 'App',
  components: {// ServiceWorkerUpdatePopup
  },
  created: function created() {
    this.handleFocusOut();
    this.handleResize(); //  // 检查是否在 iOS 环境中
    //  console.log("app .vue result:")
    //  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.getAppToken) {
    //   // 调用 iOS 原生方法
    //   let result = window.webkit.messageHandlers.getAppToken.postMessage(null);
    //   result.then((appToken) => {  
    //       console.log("promise result:", appToken) // value 始终为 null
    //       store.dispatch('user/saveToken', appToken)
    //       this.$bus.emit('getToken', 't')
    //   })
    //   .catch((error) => {
    //       console.log("error:", error)
    //   })
    // }
    // if (window.a5ixlz && typeof window.Android.getAppToken === 'function') {
    //   let result = window.a5ixlz.getAppToken();
    //   result.then((appToken) => {  
    //       console.log("promise result:", appToken) // value 始终为 null
    //       store.dispatch('user/saveToken', appToken)
    //       this.$bus.emit('getToken', 't')
    //   })
    //   .catch((error) => {
    //       console.log("error:", error)
    //   })
    // }
  },
  methods: {
    handleFocusOut: function handleFocusOut() {
      document.addEventListener('focusout', function () {
        document.body.scrollTop = 0;
      });
    },
    handleResize: function handleResize() {
      var clientHeight = document.documentElement.clientHeight;

      var resizeHandler = function resizeHandler() {
        var tagName = document.activeElement.tagName;

        if (tagName) {
          var inputBox = tagName === 'INPUT' || tagName === 'TEXTAREA';

          if (inputBox) {
            setTimeout(function () {
              document.activeElement.scrollIntoView();
            }, 0);
          }
        }

        var bodyHeight = document.documentElement.clientHeight;
        var ele = document.getElementById('fixed-bottom');

        if (ele) {
          if (clientHeight > bodyHeight) ele.style.display = 'none';else ele.style.display = 'block';
        }
      };

      window.addEventListener('resize', resizeHandler);
    }
  }
};