import { getLocale } from '@/lang';
import { setLanguage } from '@/core/services/cache';
var state = {
  language: getLocale()
};
var mutations = {
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    setLanguage(language);
  }
};
var actions = {
  SetLanguage: function SetLanguage(_ref, language) {
    var commit = _ref.commit;
    commit('SET_LANGUAGE', language);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};