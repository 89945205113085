//
//
//
//
//
//
//
import AppMain from './components/AppMain';
import Navbar from './components/Navbar';
export default {
  name: 'Layout',
  components: {
    Navbar: Navbar,
    AppMain: AppMain
  },
  computed: {
    hideNav: function hideNav() {
      return this.$route.meta.hideNav;
    }
  }
};