import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VueVantLocales } from '@/lang';
export default {
  name: 'Navbar',
  data: function data() {
    return {
      isHideBar: true,
      langOption: [{
        text: '中文',
        value: 'zh'
      }, {
        text: 'English',
        value: 'en'
      }]
    };
  },
  watch: {
    $route: function $route(to, from) {
      //这个地方写逻辑代码
      var toPath = to.path.split('/')[1];
      var query = to.query;
      console.log(to);

      if (toPath == 'pharmacyDetail') {
        to.meta.title = query.name;
      }

      if (toPath == 'partnerDetail') {
        to.meta.title = query.name;
      }

      if (/(XLZ)/i.test(navigator.userAgent)) {
        console.log('userAgent - false');
        this.isHideBar = false;
      } else {
        console.log('userAgent - true');
        this.isHideBar = true;
      }
    }
  },
  computed: {
    language: {
      get: function get() {
        return this.$store.state.app.language;
      },
      set: function set(val) {
        VueVantLocales(val);
        this.$i18n.locale = val;
        this.$store.dispatch('app/SetLanguage', val);
      }
    },
    pageTitle: function pageTitle() {
      return this.$route.meta.title;
    }
  },
  created: function created() {
    if (/(XLZ)/i.test(navigator.userAgent)) {
      console.log('userAgent - false');
      this.isHideBar = false;
    } else {
      console.log('userAgent - true');
      this.isHideBar = true;
    }
  },
  methods: {
    onClickLeft: function onClickLeft() {
      this.$router.go(-1); // if (this.$route.path == '/editPost') {
      //   this.$bus.emit('editPost', '')
      // } else {
      //   this.$router.go(-1)
      // }
    },
    onClickRight: function onClickRight() {// this.$router.push({ name: 'ComponentDemo' })
    },
    onClickSearch: function onClickSearch() {
      this.$router.push({
        path: '/communitySearch'
      });
    }
  }
};