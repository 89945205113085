import "core-js/modules/es.array.for-each";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
export var initPlatform = function initPlatform() {
  var UA = navigator.userAgent;
  var info = UA.match(/\s{1}DSBRIDGE[\w\.]+$/g);

  if (info && info.length > 0) {
    var infoArray = info[0].split('_');
    window.$appVersion = infoArray[1];
    window.$systemVersion = infoArray[2];
    window.$platform = infoArray[3];
  } else {
    window.$appVersion = undefined;
    window.$systemVersion = undefined;
    window.$platform = 'browser';
  }
};
export var createRandomNum = function createRandomNum(n) {
  var rnd = '';

  for (var i = 0; i < n; i++) {
    rnd += Math.floor(Math.random() * 10);
  }

  return parseInt(rnd, 10);
};
export function get(object, path) {
  var keys = path.split('.');
  var result = object;
  keys.forEach(function (key) {
    result = isDef(result[key]) ? result[key] : '';
  });
  return result;
}